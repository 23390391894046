import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
        {element}
        <Script 
            key="script-id"  
            strategy="idle"
            onLoad={() => console.log("SCRIPT LOADED")}
            onError={() => console.log("SCRIPT FAILED TO LOAD")}>
            {
            `
            console.log('common scripts');
            `
            }
        </Script>
        {/* <Script  strategy="idle" key="crave-ai-chat" src='https://crave-ai-plugin.prod.aws.cravecloud.xyz/crave-ai.js'/> */}
    </>
  )
}